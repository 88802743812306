import React, { useRef, useState } from "react"
import styled from "styled-components/macro"

import Comma from "../comma/Comma"

const CommasBlock = ({ className, count }) => {
  let commas = []
  for (var i = 0; i < (6 | count); i++) {
    commas.push(<CommaComp key={i} />)
  }

  return <Wrapper className={className}>{commas}</Wrapper>
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow: hidden;
  > svg:nth-child(1) {
    left: ${props => props.theme.grid.desktop.col(1)};
    transform: rotate(${Math.floor(Math.random() * 70) - 60}deg);
    top: ${Math.floor(Math.random() * 100) + 1}%;
    path {
      fill: ${props => props.theme.colors.green};
    }
  }
  > svg:nth-child(2) {
    left: ${props => props.theme.grid.desktop.col(3)};
    transform: rotate(${Math.floor(Math.random() * 70) - 60}deg);
    top: ${Math.floor(Math.random() * 100) + 1}%;
    path {
      fill: ${props => props.theme.colors.pink};
    }
  }

  > svg:nth-child(3) {
    left: ${props => props.theme.grid.desktop.col(5)};
    transform: rotate(${Math.floor(Math.random() * 70) - 60}deg);
    top: ${Math.floor(Math.random() * 100) + 1}%;
    path {
      fill: ${props => props.theme.colors.orange};
    }
  }
  > svg:nth-child(4) {
    left: ${props => props.theme.grid.desktop.col(7)};
    transform: rotate(${Math.floor(Math.random() * 70) - 60}deg);
    top: ${Math.floor(Math.random() * 100) + 1}%;
    path {
      fill: ${props => props.theme.colors.red};
    }
  }
  > svg:nth-child(5) {
    left: ${props => props.theme.grid.desktop.col(9)};
    transform: rotate(${Math.floor(Math.random() * 70) - 60}deg);
    top: ${Math.floor(Math.random() * 100) + 1}%;
    path {
      fill: #5bd1dd;
    }
  }
  > svg:nth-child(6) {
    left: ${props => props.theme.grid.desktop.col(11)};
    transform: rotate(${Math.floor(Math.random() * 70) - 60}deg);
    top: ${Math.floor(Math.random() * 100) + 1}%;
  }

  > svg {
    width: 25px;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }
`

const CommaComp = styled(Comma)`
  position: absolute;
`

export default CommasBlock
