import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import { Date, RichText } from "prismic-reactjs"

import AudioQuote from "../audio-quote/AudioQuote"
import Intro from "../intro/Intro"
import Gallery from "../gallery/Gallery"
import WYSIWYG from "../wysiwyg/Wysiwyg"
import ScrollingTitle from "../scrolling-title/ScrollingTitle"
import StaticQuote from "../static-quote/StaticQuote"
import Stats from "../stats/Stats"
import ScrollableAnchor from "react-scrollable-anchor"
import AudioWysiwyg from "../audio-wysiwyg/AudioWysiwyg"

let headingI = 0

function Slice(slice, i) {
  const { type, fields, primary } = slice
  const key = `slice-${type}-${i}`

  switch (type) {
    case "audio_quote":
      let subtiles_array = []
      fields.map(field => {
        subtiles_array.push({
          start: field.start_time,
          end: field.end_time,
          text: field.subtitle[0].text,
        })
      })
      return (
        <AudioQuote
          audioURL={primary.audio_file.url}
          subtitles={subtiles_array}
          key={key}
          keyColor={this.keyColor}
        ></AudioQuote>
      )
      break
    case "intro":
      return (
        <Intro
          date={primary.date}
          title={primary.title[0].text}
          blurb={<RichText render={primary.blurb} />}
          key={key}
        ></Intro>
      )
      break
    case "gallery":
      const images = fields.map(field => {
        return field.image.url
      })
      return <Gallery images={images} key={key}></Gallery>
      break
    case "wysiwyg":
      let textContent = primary.content.filter(content => {
        return content.type !== `image`
      })
      let imagesContent = primary.content.filter(content => {
        return content.type == `image`
      })
      return (
        <WYSIWYG
          images={imagesContent}
          content={textContent}
          fields={fields}
          key={key}
        ></WYSIWYG>
      )

      break
    case "heading":
      headingI++
      return (
        <ScrollingTitle
          id={`heading-${headingI}`}
          title={primary.header[0].text}
          outlined={primary.outlined}
          color={this.keyColor}
          scrollTo={this.hash == `#heading-${headingI}`}
          key={key}
          setActiveSection={this.setActiveSection}
          hashIndex={headingI}
        ></ScrollingTitle>
      )

      break
    case "static_quote":
      return (
        <StaticQuote
          quote={primary.quote ? primary.quote[0].text : null}
          context={primary.context ? primary.context[0].text : null}
          image={primary.image ? primary.image.url : null}
          keyColor={this.keyColor}
          key={key}
        ></StaticQuote>
      )
      break
    case "stats":
      let stats = fields.map(stat => {
        return {
          key: stat.key[0].text,
          value: stat.value[0].text,
        }
      })
      return <Stats stats={stats} key={key} keyColor={this.keyColor}></Stats>
      break
    case "audio_wywiwyg":
      // let stats = fields.map(stat => {
      //   return {
      //     key: stat.key[0].text,
      //     value: stat.value[0].text,
      //   }
      // })
      return <AudioWysiwyg fields={fields} audioUrl={primary.audio.url} />
      break
    default:
      return null
  }
}

const Slices = ({ data, keyColor, hash, setActiveSection }) => {
  useEffect(() => {
    headingI = 0
  }, [data, setActiveSection])

  return (
    <Wrapper>
      {data.map(Slice, { keyColor: keyColor, hash, setActiveSection })}
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default React.memo(Slices)
