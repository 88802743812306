import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components/macro"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import { media } from "../../style/breakpoints"
import { Link } from "gatsby"

import IndexIcon from "../../images/icons/index.svg"
import TimesIcon from "../../images/icons/times.svg"

const Index = ({ keyColor, stories, current }) => {
  const [showIndex, setShowIndex] = useState(false)

  useEffect(() => {
    if (showIndex) {
      disableBodyScroll()
    } else {
      enableBodyScroll()
    }
  }, [showIndex])
  return (
    <Wrapper>
      <Trigger keyColor={keyColor} onClick={() => setShowIndex(!showIndex)}>
        <IndexIcon />
        <span>Index</span>
      </Trigger>
      <Sidebar in={showIndex}>
        <Inner>
          <CloseBtn onClick={() => setShowIndex(false)}>
            <TimesIcon />
            <span>Close</span>
          </CloseBtn>
          <Break />
          <Storys>
            {stories.map((story, i) => {
              const headings = story.node.body.filter(node => {
                return node.type == "heading"
              })
              return (
                <Story
                  key={story.node._meta.uid}
                  isActive={story.node._meta.uid == current}
                  keyColor={story.node.key_color}
                >
                  <Author>
                    <Link
                      to={`/${story.node._meta.uid}/`}
                      onClick={() => {
                        setShowIndex(false)
                      }}
                    >
                      <span>{i + 1}.</span>
                      <h4>{story.node.author[0].text}</h4>
                    </Link>
                  </Author>
                  {/* {story.node._meta.uid == current && (
                    <> */}
                  {headings.map((heading, n) => {
                    return (
                      <SubItem key={n}>
                        <Link
                          to={`/${story.node._meta.uid}/#heading-${n + 1}`}
                          onClick={() => {
                            setShowIndex(false)
                          }}
                        >
                          <span>
                            {i + 1}.{n + 1}
                          </span>
                          <h3>{heading.primary.header[0].text}</h3>
                        </Link>
                      </SubItem>
                    )
                  })}
                </Story>
              )
            })}
          </Storys>
        </Inner>
      </Sidebar>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: sticky;
  top: 10px;
  z-index: 9999999;
  margin-bottom: 140px;

  ${media.largest} {
    /* position: absolute; */
    top: 10px;
    width: 100%;
    z-index: 100;
    margin-bottom: 220px;
  }
`

const Trigger = styled.div`
  background: ${props => props.keyColor};
  width: 143px;
  border-radius: 5px;
  cursor: pointer;
  padding: ${props => props.theme.sizing.s};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  left: calc(
    ${props => props.theme.grid.desktop.col(1, true)} +
      ${props => props.theme.grid.desktop.outerGutter}
  );

  ${media.largest} {
    padding: 15px;
    width: auto;
    position: absolute;
    right: ${props => props.theme.grid.mobile.outerGutter};
    left: ${props => props.theme.grid.mobile.outerGutter};
    display: flex;

    span {
      transform: translateX(-10px);
      width: 100%;
      text-align: center;
    }

    svg {
      margin-right: 0px !important;
    }
  }
  span {
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.white};
    position: relative;
    top: -3px;
  }

  svg {
    margin-right: 22px;
  }
`

const Sidebar = styled.div`
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10;
  transform: ${props => (props.in ? `translateX(0%)` : `translateX(-100%)`)};
  ${props => props.theme.transitions.default("transform")};
  ${media.largest} {
    margin-top: 0;
  }
`

const Inner = styled.div`
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  width: 425px;
  min-height: 100vh;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding-top: 38px;
  min-height: 100vh;
  overflow-y: scroll;

  ${media.largest} {
    padding-top: 16px;
    width: 100%;
    overflow-x: hidden;
  }
`

const CloseBtn = styled.div`
  width: 148px;
  background: ${props => props.theme.colors.grey};
  border-radius: 5px;
  cursor: pointer;
  padding: ${props => props.theme.sizing.s};
  margin-left: 61px;
  ${media.largest} {
    margin-left: ${props => props.theme.grid.mobile.outerGutter};
  }
  span {
    ${props => props.theme.typography.body};
    position: relative;
    top: -3px;
  }
  svg {
    margin-right: 25px;
  }
`

const Storys = styled.div``

const Story = styled.div`
  ${props =>
    props.isActive &&
    `
    background:${props.keyColor};
    padding-left: 0px;
    border-color: transparent;
    width: 100%;
    span,h3,h4{
      color: #fff;
    }

    ${media.largest} {
      background: transparent;
      span,h3,h4{
        color: ${props.theme.colors.black};
      }
    }
  `}

  border-bottom: 1px solid rgba(0,0,0,0.1);
  padding: ${props => props.theme.sizing.xxl} 0;
  padding-left: 61px;
  ${media.largest} {
    border-left: 7px solid ${props => props.keyColor};
    overflow: hidden;
  }
`

const Author = styled.div`
  a {
    display: flex;
  }

  h4 {
    ${props => props.theme.typography.title};
    padding-left: ${props => props.theme.sizing.xs};
  }

  span {
    line-height: 27px;
    font-weight: bold;
  }
`

const SubItem = styled.div`
  a {
    display: flex;
  }
  margin: ${props => props.theme.sizing.s} 0 0;
  padding-left: ${props => props.theme.sizing.xs};
  ${media.largest} {
    padding-left: 0;
  }
  span {
    line-height: 24px;
  }
  h3 {
    ${props => props.theme.typography.body};
    padding-left: ${props => props.theme.sizing.xs};
  }
`
const Break = styled.div`
  width: 148px;
  height: 3px;
  background: ${props => props.theme.colors.black};
  margin: ${props => props.theme.sizing.xl};
  margin-left: 61px;

  ${media.largest} {
    display: none;
  }
`

export default Index
