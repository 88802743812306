import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import { graphql, Link, navigate } from "gatsby"
import { media } from "../../style/breakpoints"

import CommasBlock from "../commas-block/CommasBlock"

const NextStory = ({ story }) => {
  const [transitioning, setTransitioning] = useState(false)
  return (
    // <Link to={`/${story._meta.uid}`}>
    <Wrapper
      keyColor={story.key_color}
      onClick={() => {
        // setTransitioning(true)
        // setTimeout(() => {
        navigate(`/${story._meta.uid}`)
        // setTransitioning(false)
        // }, 400)
      }}
      max={transitioning}
    >
      <CommasBlock />
      <Inner>
        <img src={story.illustration.url} />
        <Author>{story.author[0].text}</Author>
        <Title>{story.name[0].text}</Title>
      </Inner>
    </Wrapper>
    // </Link>
  )
}

const Wrapper = styled.div`
  height: 60vh;
  min-height: 550px;
  background: ${props => props.keyColor};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;
  ${props =>
    props.max &&
    `
    
  `}
  ${media.hover} {
    ${props => props.theme.transitions.default("all")}
  }
`

const Inner = styled.div`
  padding: 0 ${props => props.theme.grid.mobile.outerGutter};
  position: relative;
  z-index: 2;

  img {
    max-width: 250px;
    ${media.largest} {
      max-width: 150px;
    }
  }
`

const Author = styled.h2`
  ${props => props.theme.typography.smallTitle};
  color: ${props => props.theme.colors.white};
  margin-top: ${props => props.theme.sizing.mega};
`

const Title = styled.h1`
  ${props => props.theme.typography.h2};
  color: ${props => props.theme.colors.white};
`

export default NextStory
