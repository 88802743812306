import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import { media } from "../../style/breakpoints"
import useVisibilitySensor from "@rooks/use-visibility-sensor"
import HighlightPop from "react-highlight-pop"
import { Date, RichText } from "prismic-reactjs"

import TwitterIcon from "../../images/icons/twitter.svg"

const Intro = ({ date, title, blurb }) => {
  const _rootNode = useRef()
  const { isVisible, visibilityRect } = useVisibilitySensor(_rootNode, {
    intervalCheck: false,
    scrollCheck: true,
    resizeCheck: true,
    partialVisibility: true,
    scrollThrottle: -1,
    scrollDebounce: -1,
  })
  const [shareText, setShareText] = useState("")
  return (
    <HighlightPop
      onHighlightPop={d => setShareText(d)}
      popoverItems={itemClass => (
        <Twitter
          div
          className={itemClass}
          onClick={() =>
            window.open(
              `https://twitter.com/share?text="${shareText}" @seemescotland&url=${window.location.href}`,
              "",
              "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
            )
          }
        >
          <TwitterIcon />
          <span>Share</span>
        </Twitter>
      )}
    >
      <Wrapper ref={_rootNode} isVisible={isVisible}>
        <Col>
          <DateStamp>{date}</DateStamp>
          <Headline>{title}</Headline>
        </Col>
        <Col>
          <Blurb>{blurb}</Blurb>
        </Col>
      </Wrapper>
    </HighlightPop>
  )
}
const Twitter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    color: #fff;
  }
  svg {
    margin: 0 auto 5px;
  }
  svg path {
    fill: #fff;
  }
`

const Wrapper = styled.div`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props =>
    props.isVisible ? "translateY(0px)" : `translateY(25px)`};
  transition-delay: 0.2s;

  transition: all 0.4s ease-in;
  display: flex;
  justify-content: space-between;
  padding: 0
    calc(
      ${props => props.theme.grid.desktop.col(1)} +
        ${props => props.theme.grid.desktop.outerGutter} -
        ${props => props.theme.grid.desktop.gutterWidth}
    );

  div:nth-child(1) {
    flex: ${props => props.theme.grid.desktop.col(4)} 0 0;
  }

  div:nth-child(2) {
    flex: ${props => props.theme.grid.desktop.col(5)} 0 0;
    margin-top: calc(${props => props.theme.sizing.xs} + 27px);
    ${media.largest} {
      margin-top: 0;
    }
  }

  ${media.largest} {
    flex-direction: column;
    padding: 0 ${props => props.theme.grid.mobile.outerGutter};

    div {
      flex: 1 !important;
    }
  }

  strong {
    font-weight: bold;
  }
`

const DateStamp = styled.div`
  ${props => props.theme.typography.smallTitle};
  color: ${props => props.theme.colors.black};
  display: block;
  margin-bottom: ${props => props.theme.sizing.xs};
`

const Col = styled.div``

const Headline = styled.h2`
  ${props => props.theme.typography.h4};
  ${media.largest} {
    margin-bottom: ${props => props.theme.sizing.xl};
  }
`

const Blurb = styled.p`
  ${props => props.theme.typography.body};
  p ~ p {
    margin-top: ${props => props.theme.sizing.l};
  }
`

export default Intro
