import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import { media } from "../../style/breakpoints"

import QuoteMark from "../../images/icons/quoteMarks.svg"

const StaticQuote = ({ quote, context, image, keyColor }) => {
  const hasImage = image ? true : false
  return (
    <Wrapper hasImage={hasImage}>
      <Inner keyColor={keyColor}>
        <QuoteMark />
        <Context>{context}</Context>
        <Quote>{quote}</Quote>
        <QuoteMark />
      </Inner>
      {image && (
        <Image
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  svg {
    width: 38px;
    margin-bottom: ${props => props.theme.sizing.mega};
    &:nth-of-type(2) {
      margin-bottom: 0;
      margin-top: ${props => props.theme.sizing.mega};
      transform: rotate(180deg);
    }
  }

  ${props =>
    props.hasImage &&
    `
    text-align: left;
    display: flex;
    padding: 0 ${props.theme.grid.desktop.outerGutter};
    ${media.largest} {
      padding:  0;
      flex-direction: column-reverse;
      text-align: center;
    }
    ${Inner}{
      flex-basis: 50%;
      padding: 154px ${props.theme.grid.desktop.col(1)}!important;
      ${media.largest} {
        padding: 80px ${props.theme.grid.mobile.outerGutter}!important;
        flex-basis: auto;
      }
    }
    ${Image}{
      display: flex;
    }
  `}

  ${props =>
    props.hasImage &&
    `
      > div{
        background-color: ${props.theme.colors.grey};
      }
    `}
`

const Inner = styled.div`
  padding: 0
    calc(
      ${props => props.theme.grid.desktop.col(3, true)} +
        ${props => props.theme.grid.desktop.outerGutter}
    );

  svg {
    path {
      fill: ${props => props.keyColor};
    }
  }

  ${media.largest} {
    padding: 0 ${props => props.theme.grid.mobile.outerGutter};
  }
`

const Image = styled.div`
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat;
  flex-basis: 50%;
`

const Context = styled.span`
  ${props => props.theme.typography.body};
  display: block;
  margin-bottom: ${props => props.theme.sizing.xxl};
`
const Quote = styled.h5`
  ${props => props.theme.typography.h5};
  display: block;
  font-weight: 300;
  font-style: italic;
`

export default StaticQuote
