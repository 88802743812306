import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { RichText } from "prismic-reactjs"
import { media } from "../style/breakpoints"

import Layout from "../components/layout/Layout"
import Slices from "../components/slices/Slices"
import CTAs from "../components/ctas/Ctas"
import StoryIntro from "../components/story-intro/StoryIntro"
import StorySwitcher from "../components/story-switcher/StorySwitcher"
import NextStory from "../components/next-story/NextStory"
import Index from "../components/index/Index"
import NextSide from "../components/next-side/NextSide"
import Menu from "../components/story-menu/StoryMenu"

const StoryPage = props => {
  const stories = props.data.prismic.allStorys.edges
  const doc = props.data.prismic.story

  const [hash, setHash] = useState(0)

  const showNext = doc.next_story && doc._meta.uid !== "see-me"
  return (
    <Layout>
      <StoryIntro
        keyColor={doc.key_color}
        name={RichText.asText(doc.name)}
        author={RichText.asText(doc.author)}
        illustration={doc.illustration.url}
        introduction={doc.introduction}
      >
        <StorySwitcher
          keyColor={doc.key_color}
          stories={stories}
          current={props.pageContext.uid}
        />
      </StoryIntro>
      <Index
        keyColor={doc.key_color}
        stories={stories}
        current={props.pageContext.uid}
      />
      <StorySlicesWrapper keyColor={doc.key_color}>
        <Slices
          data={doc.body}
          keyColor={doc.key_color}
          hash={props.location.hash}
          setActiveSection={hash => {
            setHash(hash)
          }}
        />
      </StorySlicesWrapper>

      <CTAs keyColor={doc.key_color} />

      {doc.next_story && <NextStory story={doc.next_story} />}

      {doc.next_story && (
        <NextSide story={doc.next_story} hash={hash} stories={stories} />
      )}
    </Layout>
  )
}

const MenuComp = styled(Menu)`
  position: relative;
  opacity: 1;
  transform: none;
`

const StorySlicesWrapper = styled.div`
  width: 100%;
  *::selection {
    background: ${props => props.keyColor};
  }

  > div > div ~ div {
    margin: 160px 0;
  }

  ${media.largest} {
    > div > div ~ div {
      margin: 80px 0;
    }
  }
`

export default StoryPage

export const query = graphql`
  query StoryQuery($uid: String!) {
    prismic {
      allStorys(sortBy: meta_lastPublicationDate_DESC) {
        edges {
          node {
            author
            key_color
            body {
              ... on PRISMIC_StoryBodyHeading {
                type
                primary {
                  header
                }
              }
            }
            _meta {
              uid
            }
          }
        }
      }
    }
    prismic {
      story(lang: "en-gb", uid: $uid) {
        name
        illustration
        author
        key_color
        introduction
        _meta {
          uid
        }
        next_story {
          ... on PRISMIC_Story {
            name
            author
            illustration
            key_color
            _meta {
              uid
            }
          }
        }
        body {
          ... on PRISMIC_StoryBodyAudio_quote {
            type
            fields {
              end_time
              subtitle
              start_time
            }
            primary {
              audio_file {
                ... on PRISMIC__FileLink {
                  _linkType
                  url
                }
              }
            }
          }
          ... on PRISMIC_StoryBodyIntro {
            type
            primary {
              date
              blurb
              title
            }
          }
          ... on PRISMIC_StoryBodyGallery {
            type
            fields {
              image
            }
          }
          ... on PRISMIC_StoryBodyWysiwyg {
            type
            primary {
              content
            }
          }
          ... on PRISMIC_StoryBodyHeading {
            type
            primary {
              header
              outlined
            }
          }
          ... on PRISMIC_StoryBodyStatic_quote {
            type
            primary {
              context
              image
              quote
            }
          }
          ... on PRISMIC_StoryBodyStats {
            type
            fields {
              key
              value
            }
          }
          ... on PRISMIC_StoryBodyAudio_wywiwyg {
            type
            primary {
              audio {
                ... on PRISMIC__FileLink {
                  url
                }
              }
            }
            fields {
              end_time
              start_time
              text
            }
          }
        }
      }
    }
  }
`
