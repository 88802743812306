import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import { Link } from "gatsby"

import Item from "./item/Item"
import SolidChevron from "../../images/icons/solid-chevron.png"

const StorySwitcher = ({ stories, keyColor, current }) => {
  return (
    <Wrapper bg={keyColor}>
      {stories.map(story => {
        const slug = story.node._meta.uid
        return (
          <Link to={`/${slug}`} activeClassName="active">
            <LinkWrapper>
              {slug == current ? (
                <ActiveItem
                  type={slug == current ? "active" : "inactive"}
                  fill={keyColor}
                >
                  <SolidChevron />
                  <SolidChevron />
                </ActiveItem>
              ) : (
                <InactiveItem type={"inactive"} />
              )}
            </LinkWrapper>
          </Link>
        )
      })}
    </Wrapper>
  )
}

const InactiveItem = styled(Item)``

const ActiveItem = styled(Item)`
  display: flex;
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.1);
`

const Wrapper = styled.div`
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  display: flex;
  background: ${props => props.bg};
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  padding: ${props => props.theme.sizing.xxs};

  /* a {
    ${ActiveItem} {
      display: none;
    }
    &.active {
      ${ActiveItem} {
        display: flex;
      }
      ${InactiveItem} {
        display: none;
      }
    }
  } */
`

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

export default React.memo(StorySwitcher)
