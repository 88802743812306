import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import { media } from "../../style/breakpoints"
import { navigate } from "gatsby"

import Chevron from "../../images/icons/cta.svg"

const NextSide = ({ story, hash, stories }) => {
  const fullNextStory = stories.filter(item => {
    if (item.node._meta && story._meta) {
      return item.node._meta.uid == story._meta.uid
    }
  })[0]

  const headings = fullNextStory.node.body.filter(bodyItem => {
    return bodyItem.__typename == "PRISMIC_StoryBodyHeading"
  })

  const getHeadingType = name => {
    if (hash == 0) {
      return `${name}'s story`
    }
    return headings[hash - 1].primary.header[0].text
  }
  return (
    <Wrapper
      keyColor={story.key_color}
      onClick={() => {
        navigate(`/${story._meta.uid}#heading-${hash}`)
      }}
    >
      <Chevron />
      <Title>
        <div>
          <span>{getHeadingType(story.author[0].text.split(" ")[0])}</span>
        </div>
      </Title>
    </Wrapper>
  )
}

const Title = styled.div`
  transform: rotate(-90deg);
  transform-origin: bottom;
  opacity: 0;
  ${props => props.theme.transitions.default("all")};
  div {
    background: ${props => props.theme.colors.grey};
    position: absolute;
    top: -27px;
    right: 14px;
    padding: 15px;
    border-radius: 5px 0px 0 5px;
    span {
      white-space: nowrap;
      font-family: ${props => props.theme.typography.body};
    }
  }
`

const Wrapper = styled.div`
  background: ${props => props.theme.colors.grey};
  padding: ${props => props.theme.sizing.s};
  position: fixed;
  bottom: 50vh;
  right: 0;
  z-index: 1;
  text-align: center;
  border-radius: 5px 0px 0 5px;
  cursor: pointer;

  svg {
    path {
      fill: ${props => props.keyColor};
    }
  }

  ${media.hover} {
    &:hover {
      > div {
        opacity: 1;
      }
    }
  }
`

export default NextSide
