import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import { media } from "../../style/breakpoints"

const Stats = ({ stats, keyColor }) => {
  return (
    <Wrapper>
      <Inner>
        {stats.map((stat, i) => {
          return (
            <Stat key={i} keyColor={keyColor}>
              <Value>
                <Main>{stat.value}</Main>
                <Shadow>{stat.value}</Shadow>
              </Value>
              <Key>{stat.key}</Key>
            </Stat>
          )
        })}
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0
    calc(
      ${props => props.theme.grid.desktop.col(1, false)} +
        ${props => props.theme.grid.desktop.outerGutter}
    );

  ${media.largest} {
    flex-direction: column;
    padding: 0 ${props => props.theme.grid.mobile.outerGutter};
  }
`

const Stat = styled.div`
  text-align: center;
  padding: 0 15px;

  h4 {
    color: ${props => props.keyColor};
    -webkit-text-stroke-color: ${props => props.keyColor};
  }

  span {
    color: ${props => props.keyColor};
  }

  ${media.largest} {
    padding: 0;
    margin-bottom: ${props => props.theme.sizing.mega};
  }
`
const Value = styled.div`
  position: relative;
  margin-bottom: ${props => props.theme.sizing.xl};
`

const Main = styled.h4`
  ${props => props.theme.typography.h2}
`

const Shadow = styled.h4`
  ${props => props.theme.typography.h2};
  position: absolute;
  top: 3px;
  left: calc(50% + 2px);
  transform: translateX(-50%);
  z-index: -1;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000;
  color: transparent !important;
`

const Key = styled.span`
  ${props => props.theme.typography.body};
`

export default Stats
