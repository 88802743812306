import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { TimelineMax } from "gsap/dist/gsap"
import Commas from "../commas-block/CommasBlock"
import { Date, RichText } from "prismic-reactjs"

import { media } from "../../style/breakpoints"

import CtaIcon from "../../images/icons/cta.svg"

const StoryIntro = ({
  keyColor,
  name,
  author,
  illustration,
  children,
  introduction,
}) => {
  const _title = useRef()
  const _author = useRef()
  const _ill = useRef()
  const _intro = useRef()

  const tl = new TimelineMax({ paused: false, id: "myTimeline" })

  useEffect(() => {
    tl.to(_title.current, 1.5, {
      opacity: 1,
      transform: `translateY(0%)`,
    })
      .to(
        _author.current,
        1.5,
        {
          opacity: 1,
        },
        "-=0.5"
      )
      .to(
        _ill.current,
        2,
        {
          opacity: 1,
        },
        "-0"
      )
      .to(
        _intro.current,
        2,
        {
          opacity: 1,
        },
        "-=1"
      )
    return () => {}
  }, [])

  return (
    <Wrapper bg={keyColor}>
      <Outer>
        <Commas />
        <Inner>
          <Illustration ref={_ill}>
            <img src={illustration} alt="" />
          </Illustration>
          <Author ref={_author}>{author}</Author>
          <Title ref={_title}>{name}</Title>
          {introduction && (
            <Intro ref={_intro}>
              <RichText render={introduction} />
            </Intro>
          )}
        </Inner>
        <More
          onClick={() => {
            window.scrollTo({
              top: window.innerHeight,
              behavior: "smooth",
            })
          }}
        >
          <span>Find out more</span>
          <CtaIcon />
        </More>
        {children}
      </Outer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${props => props.bg};
  height: 95vh;
  min-height: 700px;
  position: relative;
  margin-bottom: 160px;
  z-index: 2;
  ${media.largest} {
    margin-bottom: 80px;
    height: 80vh;
  }
`

const Illustration = styled.div`
  margin: 0 auto ${props => props.theme.sizing.mega};
  opacity: 0;
  img {
    max-width: 275px;
  }

  ${media.largest} {
    width: 115px;
    margin: 0 auto ${props => props.theme.sizing.l};
    img {
      max-width: 100%;
    }
  }
`

const Outer = styled.div`
  overflow: hidden;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 95vh;
  min-height: 803px;
  text-align: center;
  padding: 0 ${props => props.theme.grid.mobile.outerGutter};
  position: relative;
  z-index: 1;
  ${media.largest} {
    height: 80vh;
  }
`

const Intro = styled.div`
  opacity: 0;
  p {
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.white};
    max-width: 600px;
    margin: ${props => props.theme.sizing.l} auto 0;
  }
`

const Author = styled.h2`
  ${props => props.theme.typography.smallTitle};
  color: ${props => props.theme.colors.white};
  opacity: 0;
`

const Title = styled.h1`
  ${props => props.theme.typography.h2};
  color: ${props => props.theme.colors.white};
  opacity: 0;
  transform: translateY(20%);
`
const More = styled.div`
  display: flex;
  position: absolute;
  bottom: 25px;
  right: 25px;
  z-index: 2;
  opacity: 0.5;
  cursor: pointer;
  span {
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.white};
    line-height: 19px;
  }
  svg {
    margin-left: 10px;
    transform: rotate(90deg);
    path {
      fill: #fff;
    }
  }

  ${media.largest} {
    right: 50%;
    transform: translateX(50%);
    bottom: 50px;
  }

  ${media.hover} {
    ${props => props.theme.transitions.default("opacity")};
    &:hover {
      opacity: 1;
    }
  }
`

export default StoryIntro
