import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components/macro"
import useVisibilitySensor from "@rooks/use-visibility-sensor"
import useWindowScrollPosition from "@rehooks/window-scroll-position"

const ScrollingTitle = ({
  title,
  outlined,
  color,
  id,
  scrollTo,
  setActiveSection,
  hashIndex,
}) => {
  const _rootNode = useRef()
  const _title = useRef()

  const { isVisible, visibilityRect } = useVisibilitySensor(_rootNode, {
    intervalCheck: false,
    scrollCheck: true,
    resizeCheck: true,
    partialVisibility: true,
    scrollThrottle: -1,
    scrollDebounce: -1,
  })

  let options = {
    throttle: -1,
  }

  if (typeof window !== `undefined`) {
    let position = useWindowScrollPosition(options)
  }

  useEffect(() => {
    if (scrollTo) {
      window.scrollTo({
        top: _title.current.offsetTop + window.scrollY - 200,
        behavior: "smooth",
      })
    }
  }, [scrollTo])

  useEffect(() => {
    if (isVisible) {
      setActiveSection(hashIndex)
    }
  }, [isVisible])

  const getShiftValue = () => {
    if (!_rootNode.current || !_title.current || !isVisible) {
      return
    }

    let titleBB = _title.current.getBoundingClientRect()
    let rootBB = _rootNode.current.getBoundingClientRect()
    let shiftVal =
      (rootBB.top / window.innerHeight) * _title.current.scrollWidth +
      -0.5 * titleBB.top
    return shiftVal
  }
  // }

  return (
    <Wrapper ref={_rootNode} id={id}>
      <Inner>
        <Title
          ref={_title}
          shift={getShiftValue()}
          outlined={outlined}
          color={color}
        >
          {title}
        </Title>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: ${props => props.theme.sizing.xl} 0;
  width: 100%;
  height: 100vh;
  position: relative;
`

const Inner = styled.div`
  position: sticky;
  overflow-x: hidden;
  width: 100%;
  top: 0;
  /* overflow: hidden; */
  transform: translateY(50%);
  height: calc(11.9522vw + 72.5179px);
`

const Title = styled.div`
  ${props => props.theme.typography.h1};
  line-height: auto !important;
  white-space: pre;
  transform: ${props =>
    props.shift
      ? `translate3d(${props.shift}px, 0px, 0px)`
      : `translate3d(3000px, 0px, 0px)`};
  will-change: transform;
  backface-visibility: hidden;
  color: ${props => props.color};
  will-change: transform;
  height: 100%;

  ${props =>
    props.outlined == true &&
    `
    @supports (-webkit-text-stroke: 1px black) {
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: ${props.color ? props.color : "black"};
      color: transparent;
    }
  `}
`

export default React.memo(ScrollingTitle)
