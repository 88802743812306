import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components/macro"
import { media } from "../../style/breakpoints"
import HighlightPop from "react-highlight-pop"
import useVisibilitySensor from "@rooks/use-visibility-sensor"
import useWindowScrollPosition from "@rehooks/window-scroll-position"
import { Date, RichText } from "prismic-reactjs"

import TwitterIcon from "../../images/icons/twitter.svg"

const Wysiwyg = ({ content, images, fields }) => {
  // if (typeof window == `undefined`) {
  //   return <span></span>
  // }

  const _rootNode = useRef()
  const _rail = useRef()
  let getShiftValue = null

  const { isVisible, visibilityRect } = useVisibilitySensor(_rootNode, {
    intervalCheck: false,
    scrollCheck: true,
    resizeCheck: true,
    partialVisibility: true,
    scrollThrottle: -1,
    scrollDebounce: -1,
  })

  let options = {
    throttle: -1,
  }

  // if (typeof window !== `undefined`) {
  //   let position = useWindowScrollPosition(options)
  // }

  const [shareText, setShareText] = useState("")

  getShiftValue = () => {
    if (!_rootNode.current || !_rail.current || !isVisible) {
      return
    }

    let railBB = _rail.current.getBoundingClientRect()
    let rootBB = _rootNode.current.getBoundingClientRect()
    let shiftVal =
      (rootBB.top / (window.innerHeight * 2)) * _rail.current.scrollWidth +
      -0.2 * _rail.current.scrollWidth +
      500
    // let lock = 0.19 * window.innerWidth
    return shiftVal
  }

  const _content = <RichText render={content} />

  return (
    <Wrapper ref={_rootNode}>
      <HighlightPop
        onHighlightPop={d => setShareText(d)}
        popoverItems={itemClass => (
          <Twitter
            div
            className={itemClass}
            onClick={() =>
              window.open(
                `https://twitter.com/share?text="${shareText}" @seemescotland&url=${window.location.href}`,
                "",
                "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
              )
            }
          >
            <TwitterIcon />
            <span>Share</span>
          </Twitter>
        )}
      >
        <Inner isVisible={isVisible}>{_content}</Inner>
      </HighlightPop>
      {images.length > 0 && (
        <Carousel>
          <CarouselInner>
            <Rail shift={getShiftValue()} ref={_rail}>
              {images.map(image => {
                return (
                  <Image>
                    <img src={image.url} alt={image.alt} />
                  </Image>
                )
              })}
            </Rail>
          </CarouselInner>
        </Carousel>
      )}

      {images.length > 0 && (
        <Images>
          {images.map(image => {
            return (
              <Image>
                <img src={image.url} alt={image.alt} />
              </Image>
            )
          })}
        </Images>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Images = styled.div`
  display: flex;
  flex-direction: column;
  display: none;
  justify-content: space-between;
  padding: 0 ${props => props.theme.grid.mobile.outerGutter};
  ${media.largest} {
    display: flex;
  }

  > div {
    margin: 0;
  }
  img {
    width: 100%;
    vertical-align: bottom;
    height: auto !important;
  }
`

const Twitter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    color: #fff;
  }
  svg {
    margin: 0 auto 5px;
  }
  svg path {
    fill: #fff;
  }
`

const Inner = styled.div`
  padding: 0
    calc(
      ${props => props.theme.grid.desktop.col(3, true)} +
        ${props => props.theme.grid.desktop.outerGutter}
    );

  ${media.custom(1500)} {
    padding: 0
      calc(
        ${props => props.theme.grid.desktop.col(2, true)} +
          ${props => props.theme.grid.desktop.outerGutter}
      );
  }

  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props =>
    props.isVisible ? "translateY(0px)" : `translateY(25px)`};
  transition-delay: 0.2s;
  transition: all 0.4s ease-in;

  ${media.largest} {
    padding: 0 ${props => props.theme.grid.mobile.outerGutter};
  }

  h2 {
    ${props => props.theme.typography.h4};
    margin-bottom: ${props => props.theme.sizing.l};
  }

  h3 {
    ${props => props.theme.typography.h5};
    margin-bottom: ${props => props.theme.sizing.xl};
  }

  h4 {
    ${props => props.theme.typography.h6};
    margin-bottom: ${props => props.theme.sizing.l};
  }

  p,
  li {
    ${props => props.theme.typography.body};
  }

  p ~ p,
  p ~ li,
  li ~ li {
    margin-top: ${props => props.theme.sizing.l};
  }

  ul {
    margin-top: ${props => props.theme.sizing.l};
    padding-left: ${props => props.theme.sizing.l};
    list-style-type: circle;
  }

  p + h2 {
    margin-top: ${props => props.theme.sizing.mega};
  }

  p + h3 {
    margin-top: ${props => props.theme.sizing.xl};
  }

  p + h4 {
    margin-top: ${props => props.theme.sizing.xl};
  }

  strong {
    font-weight: bold;
  }
`
const Carousel = styled.div`
  max-width: 100%;
  /* overflow: hidden;
  overflow-x: scroll; */
  margin-top: ${props => props.theme.sizing.mega};
  height: 100vh;
  ${media.largest} {
    display: none;
  }
`

const CarouselInner = styled.div`
  position: sticky;
  top: 200px;
  overflow-x: hidden;
  width: 100%;
`

const Image = styled.div`
  margin: 0 25px 0 0;
  img {
    height: 450px;
  }
`

const Rail = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  will-change: transform;
  backface-visibility: hidden;
  transform: ${props =>
    props.shift
      ? `translate3d(${props.shift}px, 0px, 0px)`
      : `translate3d(3000px, 0px, 0px)`};
`

export default React.memo(Wysiwyg)
