import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import { media } from "../../../style/breakpoints"

import SolidChevron from "../../../images/icons/solid-chevron.svg"

const Item = ({ className, type, fill }) => {
  if (type == "inactive") {
    return <Inactive className={className}></Inactive>
  } else {
    return (
      <Active className={className} fill={fill}>
        <div>
          <SolidChevron />
          <SolidChevron />
        </div>
      </Active>
    )
  }
}

const Inactive = styled.div`
  border-radius: 9999em;
  background: #fff;
  width: 13px;
  height: 13px;
  margin: 0 ${props => props.theme.sizing.xxs};

  ${media.hover} {
    ${props => props.theme.transitions.default("all")};
    &:hover {
      border-radius: 10px;
      transform: scale(1.5);
    }
  }
`

const Active = styled.div`
  border-radius: 10px;
  background: #fff;
  width: 39px;
  height: 39px;
  margin: 0 ${props => props.theme.sizing.xxs};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  svg {
    path {
      fill: ${props => props.fill};
    }
  }

  svg:nth-child(1) {
    transform: rotate(180deg);
  }

  > div {
    svg {
      margin: 0 3px;
    }
  }
`

export default Item
