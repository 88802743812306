import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import { media } from "../../style/breakpoints"
import useVisibilitySensor from "@rooks/use-visibility-sensor"

const Gallery = ({ images }) => {
  const _rootNode = useRef()
  const _title = useRef()
  const { isVisible, visibilityRect } = useVisibilitySensor(_rootNode, {
    intervalCheck: false,
    scrollCheck: true,
    resizeCheck: true,
    partialVisibility: true,
    scrollThrottle: -1,
    scrollDebounce: -1,
  })

  return (
    <Wrapper ref={_rootNode}>
      <Inner number={images.length} isVisible={isVisible}>
        {images.map((image, i) => (
          <Image
            style={{
              transitionDelay: `${i + 1 * 100}ms`,
            }}
          >
            <img key={i} src={image} />
          </Image>
        ))}
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Inner = styled.div`
  padding: 0 ${props => props.theme.grid.desktop.outerGutter};
  padding: 0
    calc(
      ${props => props.theme.grid.desktop.outerGutter} +
        ${props => props.theme.grid.desktop.col(1)} -
        ${props => props.theme.grid.desktop.gutterWidth}
    );

  > div {
    opacity: ${props => (props.isVisible ? 1 : 0)};
    margin-top: ${props => (props.isVisible ? "0px" : `25px`)};
    transition: all 0.4s ease-in;
  }

  ${media.largest} {
    padding: 0 ${props => props.theme.grid.mobile.outerGutter};
  }

  ${props =>
    props.number > 1 &&
    `
      display: flex;
      padding: ${props.theme.sizing.m} ${props.theme.grid.desktop.outerGutter};
      justify-content: space-between;
      > div{
        width: calc(${100 / props.number}% - ${props.theme.sizing.xl});
        &:nth-child(odd){
          transform: translateY(-${props.theme.sizing.m});
        }
        &:nth-child(even){
          transform: translateY(${props.theme.sizing.m});
        }
      }

  `}

  ${media.largest} {
    ${props =>
      props.number > 1 &&
      `
          padding: 0 ${props => props.theme.grid.mobile.outerGutter};
          flex-direction: column;
          > div{
            width: 100%;
            max-width: 100%;
            transform: none!important;
            margin-top: ${props.theme.sizing.m};
            &:nth-child(1) {
              margin-top: 0;
            }
          }
    `}
  }
`

const Image = styled.div`
  img {
    width: 100%;
    vertical-align: bottom;
  }
`

export default Gallery
