import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components/macro"
import { media } from "../../style/breakpoints"

import Audio from "../../images/icons/audio.svg"

import CommasBlock from "../commas-block/CommasBlock"

const AudioQuote = ({ audioURL, subtitles, name, keyColor }) => {
  const audioPlayer = useRef()

  const [currentAudioTime, setCurrentAudioTime] = useState(0)
  const [isPaused, setIsPaused] = useState(null)

  const audioTrigger = () => {
    if (audioPlayer.current.paused) {
      audioPlayer.current.play()
      setIsPaused(false)
    } else {
      audioPlayer.current.pause()
      setIsPaused(true)
    }
  }

  const audioTimeUpdated = () => {
    setCurrentAudioTime(audioPlayer.current.currentTime)
  }

  const audioPaused = () => {
    let activeBlock = subtitles.filter(block => {
      return (
        block.end > audioPlayer.current.currentTime &&
        block.start < audioPlayer.current.currentTime
      )
    })

    if (activeBlock.length > 0) {
      audioPlayer.current.currentTime = activeBlock[0].start
    }
  }

  const audioPlayed = () => {
    setIsPaused(false)
  }

  useEffect(() => {
    if (!audioPlayer.current) {
      return
    }
    audioPlayer.current.addEventListener("timeupdate", audioTimeUpdated)
    audioPlayer.current.addEventListener("pause", audioPaused)
    audioPlayer.current.addEventListener("play", audioPlayed)
    audioPlayer.current.addEventListener("ended", () => setIsPaused(null))
    return () =>
      audioPlayer.current.removeEventListener("timeupdate", audioTimeUpdated)
    audioPlayer.current.removeEventListener("pause", audioPaused)
    audioPlayer.current.removeEventListener("play", audioPlayed)
    audioPlayer.current.addEventListener("ended", () => setIsPaused(null))
  }, [])

  const getSubtitles = () => {
    let text = subtitles.map(block => {
      let stringSplit = block.text.split(" ")
      let activeBlock =
        block.end > currentAudioTime && block.start < currentAudioTime
      let isRead =
        block.end < currentAudioTime && block.start < currentAudioTime

      let transtionDelay = (block.end - block.start) / stringSplit.length

      let words = stringSplit.map((word, i) => {
        return (
          <span
            key={i}
            style={
              activeBlock
                ? { opacity: 1, transitionDelay: `${transtionDelay * i}s` }
                : isRead
                ? { opacity: 1 }
                : {}
            }
          >
            {word}
          </span>
        )
      })
      return words
    })
    return text
  }

  if (!subtitles) {
    return <></>
  }

  return (
    <Wrapper keyColor={keyColor}>
      <CommasBlockComp />
      <Inner>
        <audio controls ref={audioPlayer} preload="auto">
          <source src={audioURL} type="audio/mpeg" />
        </audio>

        <AudioTrigger onClick={audioTrigger} isPaused={isPaused}>
          <AudioIcon />
          <span>Click to listen in on {name}</span>
        </AudioTrigger>

        <Subtitles>{getSubtitles()}</Subtitles>
      </Inner>
    </Wrapper>
  )
}

const Inner = styled.div`
  position: relative;
  z-index: 1;
`

const CommasBlockComp = styled(CommasBlock)`
  z-index: 0;
`

const AudioTrigger = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-bottom: ${props => props.theme.sizing.mega};
  ${props => props.theme.transitions.default("opacity")};
  span {
    ${props => props.theme.typography.body};
    color: ${props => props.theme.colors.white};
    font-size: 14px;
    font-style: italic;
  }

  ${props =>
    props.isPaused == false &&
    `
    opacity: 0.5;
  `}
`

const Wrapper = styled.div`
  background: ${props =>
    props.keyColor ? props.keyColor : props.theme.colors.green};
  text-align: center;
  padding: ${props => props.theme.sizing.mega} 0
    calc(
      ${props => props.theme.sizing.mega} + ${props => props.theme.sizing.mega}
    );
  position: relative;
  overflow: hidden;

  margin-left: ${props => props.theme.grid.desktop.outerGutter}!important;
  margin-right: ${props => props.theme.grid.desktop.outerGutter}!important;

  ${media.largest} {
    padding: ${props => props.theme.sizing.mega} 0;
    margin-left: ${props => props.theme.grid.mobile.outerGutter}!important;
    margin-right: ${props => props.theme.grid.mobile.outerGutter}!important;
  }
  audio {
    position: fixed;
    left: -9999px;
  }
`

const Subtitles = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: pre;
  max-width: ${props => props.theme.grid.desktop.col(7)};
  justify-content: center;
  margin: 0 auto;

  ${media.largest} {
    max-width: ${props => props.theme.grid.mobile.col(4)};
    padding: 0 ${props => props.theme.sizing.mega};
  }

  span {
    opacity: 0.2;
    white-space: pre;
    margin-right: 5px;
    ${props => props.theme.typography.h5};
    color: ${props => props.theme.colors.white};
    transition: all 0.2s;
  }

  .active {
    opacity: 1;
  }
`

const AudioIcon = styled(Audio)`
  margin: 0 auto;
  display: block;
`

export default React.memo(AudioQuote)
