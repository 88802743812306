import React, { useRef, useState } from "react"
import styled from "styled-components/macro"
import { media } from "../../style/breakpoints"

import CtaIcon from "../../images/icons/cta.svg"

const Ctas = ({ keyColor }) => {
  return (
    <Wrapper keyColor={keyColor}>
      <Inner>
        <Title>Get Involved with See Me</Title>
        <Blurb>
          There are so many ways to get involved and engage with the movement,
          from becoming a supporter or volunteer to partnering with us to
          growing the movement in your organisation.
        </Blurb>
        <CtasWrapper keyColor={keyColor}>
          <a
            href="https://www.seemescotland.org/movement-for-change/our-volunteers/"
            target="_blank"
          >
            <Cta keyColor={keyColor}>
              <CtaTitle>Volunteers</CtaTitle>
              <CtaBlurb>Volunteers are a vital part of the movement.</CtaBlurb>
              <Button></Button>
            </Cta>
          </a>
          <a
            href="https://www.seemescotland.org/movement-for-change/get-involved/partner-with-us/"
            target="_blank"
          >
            <Cta keyColor={keyColor}>
              <CtaTitle>Partner With Us</CtaTitle>
              <CtaBlurb>
                Do you want to grow the moment within your local area or
                organisation?
              </CtaBlurb>
              <Button></Button>
            </Cta>
          </a>
          <a
            href="https://www.seemescotland.org/resources/"
            target="_blank"
            onClick={() => {
              ga("send", "event", "Report - Start your journey")
            }}
          >
            <Cta keyColor={keyColor}>
              <CtaTitle>Start Your Journey</CtaTitle>
              <CtaBlurb>Get everything you need to take action.</CtaBlurb>
              <Button></Button>
            </Cta>
          </a>
        </CtasWrapper>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  svg {
    path {
      fill: ${props => props.keyColor};
    }
  }
  margin-bottom: ${props => props.theme.sizing.mega};
`

const Inner = styled.div`
  padding: 0 ${props => props.theme.grid.desktop.outerGutter};
  padding: 0
    calc(
      ${props => props.theme.grid.desktop.outerGutter} +
        ${props => props.theme.grid.desktop.col(1)} -
        ${props => props.theme.grid.desktop.gutterWidth}
    );
  ${media.custom(1111)} {
    padding: 0 ${props => props.theme.grid.mobile.outerGutter};
  }
`

const Title = styled.h3`
  ${props => props.theme.typography.h4};
  text-align: center;
  margin-bottom: ${props => props.theme.sizing.xxl};
`
const Blurb = styled.p`
  ${props => props.theme.typography.body};
  text-align: center;
  max-width: ${props => props.theme.grid.desktop.col(4)};
  margin: 0 auto ${props => props.theme.sizing.xxl};
  ${media.custom(1111)} {
    text-align: left;
    max-width: 100%;
  }
`
const CtasWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  a {
    flex-basis: calc(33.3% - ${props => props.theme.sizing.xxl});
    border-top: 2px solid;
    padding: ${props => props.theme.sizing.xxl};
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: space-between;

    ${media.custom(1111)} {
      padding: ${props => props.theme.sizing.xxl} 0;
    }
  }

  ${media.hover} {
    a {
      &:hover {
        border-color: ${props => props.keyColor};
      }
    }
  }

  ${media.custom(1111)} {
    flex-direction: column;
  }
`

const CtaTitle = styled.h4`
  ${props => props.theme.typography.smallTitle};
  font-weight: bold;
  margin-bottom: ${props => props.theme.sizing.l};
`
const CtaBlurb = styled.p`
  ${props => props.theme.typography.smallTitle};
`

const Cta = styled.div`

  ${media.hover} {
    &:hover {
      ${CtaTitle} {
        color: ${props => props.keyColor};
      }
      /* ${CtaBlurb} {
        text-decoration: underline;
      } */

      svg {
        opacity: 1;
      }
    }
  }

  ${media.custom(1111)} {
    padding: ${props => props.theme.sizing.mega} 0;
  }
`

const Button = styled(CtaIcon)`
  margin-top: ${props => props.theme.sizing.m};
  opacity: 0;
  ${props => props.theme.transitions.default("opacity")};
  ${media.largest} {
    opacity: 1;
  }
`

export default Ctas
