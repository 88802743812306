import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components/macro"
import { media } from "../../style/breakpoints"
import HighlightPop from "react-highlight-pop"
import useVisibilitySensor from "@rooks/use-visibility-sensor"
import useWindowScrollPosition from "@rehooks/window-scroll-position"
import { Date, RichText } from "prismic-reactjs"

import TwitterIcon from "../../images/icons/twitter.svg"
import AudioCircle from "../../images/icons/audio-circle.svg"

const AudioWysiwyg = ({ fields, audioUrl }) => {
  const _rootNode = useRef()
  const audioPlayer = useRef()

  const { isVisible, visibilityRect } = useVisibilitySensor(_rootNode, {
    intervalCheck: false,
    scrollCheck: true,
    resizeCheck: true,
    partialVisibility: true,
    scrollThrottle: -1,
    scrollDebounce: -1,
  })

  const [shareText, setShareText] = useState("")
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentAudioTime, setCurrentAudioTime] = useState(0)

  const togglePlay = () => {
    if (audioPlayer.current.paused) {
      audioPlayer.current.play()
      setIsPlaying(true)
    } else {
      audioPlayer.current.pause()
      setIsPlaying(false)
    }
  }

  const audioPaused = () => {
    // let activeBlock = subtitles.filter(block => {
    //   return (
    //     block.end > audioPlayer.current.currentTime &&
    //     block.start < audioPlayer.current.currentTime
    //   )
    // })
    // if (activeBlock.length > 0) {
    //   audioPlayer.current.currentTime = activeBlock[0].start
    // }
  }

  const audioPlayed = () => {
    setIsPlaying(true)
  }

  const audioTimeUpdated = () => {
    setCurrentAudioTime(audioPlayer.current.currentTime)
  }

  useEffect(() => {
    if (!audioPlayer.current) {
      return
    }
    audioPlayer.current.addEventListener("timeupdate", audioTimeUpdated)
    audioPlayer.current.addEventListener("pause", audioPaused)
    audioPlayer.current.addEventListener("play", audioPlayed)
    audioPlayer.current.addEventListener("ended", () => setIsPlaying(null))
    return () =>
      audioPlayer.current.removeEventListener("timeupdate", audioTimeUpdated)
    audioPlayer.current.removeEventListener("pause", audioPaused)
    audioPlayer.current.removeEventListener("play", audioPlayed)
    audioPlayer.current.addEventListener("ended", () => setIsPlaying(null))
  }, [])

  return (
    <Wrapper ref={_rootNode}>
      <audio controls ref={audioPlayer}>
        <source src={audioUrl} type="audio/mpeg" preload="auto" />
      </audio>
      <HighlightPop
        onHighlightPop={d => setShareText(d)}
        popoverItems={itemClass => (
          <Twitter
            div
            className={itemClass}
            onClick={() =>
              window.open(
                `http://twitter.com/share?text="${shareText}" @seemescotland&url=${window.location.href}`,
                "",
                "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600"
              )
            }
          >
            <TwitterIcon />
            <span>Share</span>
          </Twitter>
        )}
      >
        <Inner isVisible={isVisible}>
          <AudioTrigger
            onClick={() => {
              togglePlay()
            }}
            isPlaying={isPlaying}
          >
            <AudioCircle />
          </AudioTrigger>

          {fields.map((section, i) => {
            let activeBlock =
              section.end_time > currentAudioTime &&
              section.start_time < currentAudioTime
            let isRead =
              section.end_time < currentAudioTime &&
              section.start_time < currentAudioTime
            return (
              <Section active={activeBlock} isRead={isRead}>
                <p key={i}>{section.text[0].text}</p>
              </Section>
            )
          })}
        </Inner>
      </HighlightPop>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  audio {
    position: fixed;
    left: -999999px;
  }
`

const Section = styled.div`
  margin-bottom: ${props => props.theme.sizing.l};
  ${props =>
    props.active &&
    `
    p{
      text-shadow: 0 0 1px black;
    }
  `}
  p {
    ${props => props.theme.transitions.default("all")};
  }
`

const AudioTrigger = styled.div`
  cursor: pointer;
  opacity: 0.6;
  ${props =>
    props.isPlaying &&
    `
    opacity: 1;
  `}
  ${media.hover} {
    &:hover {
      opacity: 1;
    }
  }
  svg {
    transform: translateY(100%) translateX(-150%);
    ${props => props.theme.transitions.default("all")};
    ${media.largest} {
      transform: translateY(0%) translateX(0%);
    }
  }
`

const Twitter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    color: #fff;
  }
  svg {
    margin: 0 auto 5px;
  }
  svg path {
    fill: #fff;
  }
`

const Inner = styled.div`
  padding: 0
    calc(
      ${props => props.theme.grid.desktop.col(3, true)} +
        ${props => props.theme.grid.desktop.outerGutter}
    );

  ${media.custom(1500)} {
    padding: 0
      calc(
        ${props => props.theme.grid.desktop.col(2, true)} +
          ${props => props.theme.grid.desktop.outerGutter}
      );
  }

  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props =>
    props.isVisible ? "translateY(0px)" : `translateY(25px)`};
  transition-delay: 0.2s;
  transition: all 0.4s ease-in;
  position: relative;

  ${media.largest} {
    padding: 0 ${props => props.theme.grid.mobile.outerGutter};
  }

  h2 {
    ${props => props.theme.typography.h4};
    margin-bottom: ${props => props.theme.sizing.l};
  }

  h3 {
    ${props => props.theme.typography.h5};
    margin-bottom: ${props => props.theme.sizing.xl};
  }

  h4 {
    ${props => props.theme.typography.h6};
    margin-bottom: ${props => props.theme.sizing.l};
  }

  p,
  li {
    ${props => props.theme.typography.body};
  }

  p ~ p,
  p ~ li,
  li ~ li {
    margin-top: ${props => props.theme.sizing.l};
  }

  ul {
    margin-top: ${props => props.theme.sizing.l};
    padding-left: ${props => props.theme.sizing.l};
    list-style-type: circle;
  }

  p + h2 {
    margin-top: ${props => props.theme.sizing.mega};
  }

  p + h3 {
    margin-top: ${props => props.theme.sizing.xl};
  }

  p + h4 {
    margin-top: ${props => props.theme.sizing.xl};
  }
`

export default React.memo(AudioWysiwyg)
